window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

// Adiciona a meta tag robots para evitar indexação
const metaTag = document.createElement('meta');
metaTag.name = "robots";
metaTag.content = "noindex, nofollow";
document.head.appendChild(metaTag);

// Função para obter o valor de um parâmetro específico da URL
function getQueryParam(param) {
    var urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

// Esperar até que o DOM seja completamente carregado antes de inicializar os pixels
document.addEventListener("DOMContentLoaded", function() {
    // Obter o valor do parâmetro "page"
    var pageValue = getQueryParam('page');

    // Inicializar o pixel de acordo com o valor do parâmetro "page"
    if (pageValue === 'clubsanitario') {
        // Inicializar o pixel 738613110662481
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '738613110662481'); 
        fbq('track', 'PageView');
        console.log('Pixel 738613110662481 ativado');
    } else if (pageValue === 'dentaclip') {
        // Inicializar o pixel 1298563547635219
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1298563547635219'); 
        fbq('track', 'PageView');
        console.log('Pixel 1298563547635219 ativado');
    }
});
